import type { NextPage } from "next";
import styles from "../styles/Home.module.css";
import Image from "next/image";
import Link from "next/link";
import { routes } from "../config/routes";

const filteredRoutes = routes.slice(1);

const Home: NextPage = () => {
  return (
    <section className={styles.section}>
      <div className={styles.table}>
        {filteredRoutes.map(
          ({ href, title, image: { url, altText } }, index) => {
            return (
              <div className={styles.tableCell} key={index}>
                <Link href={href}>
                  <a className={styles.tableCellLink}>
                    {title}
                    <div className={styles.tableCellLinkImage}>
                      <Image
                        src={url}
                        alt={altText}
                        priority
                        layout="fill"
                        objectFit="contain"
                        objectPosition="center"
                      />
                    </div>
                  </a>
                </Link>
              </div>
            );
          }
        )}
      </div>
    </section>
  );
};

export default Home;
